<script>
import { api } from "@/state/services";
import { VueSvgGauge } from 'vue-svg-gauge'

export default {
  components: {
    VueSvgGauge
  },
  data() {
    return {
      points: 0,
      graduations: null,
      previous: {
        id: null,
        name: '',
        points: 0,
        image: null,
      },
      next: {
        id: null,
        name: '',
        points: 0,
        image: null
      },
      progress: 0
    };
  },
  methods: {
    getGraduation() {
      api
        .get('report/graduation')
        .then(response => {
          if (response.data.status=='success') {
            this.graduations = response.data.list
            this.points = response.data.graduation.points
            this.previous = response.data.graduation.previous
            this.next = response.data.graduation.next
            this.progress = this.points - this.previous.points

            this.$parent.$parent.graduation.id = response.data.current.id
            this.$parent.$parent.graduation.name = response.data.current.name
            this.$parent.$parent.getStatData();
          }
        })
    }
  },
  created () {
    this.getGraduation();
  }
};
</script>

<template>
  <div>
    <div style="max-width:350px;" class="m-auto">
      <vue-svg-gauge
        class="px-3"
        :start-angle="-110"
        :end-angle="110"
        :value="points - previous.points"
        :separator-step="0"
        :min="Number(0)"
        :max="Number(next.points - previous.points)"
        gauge-color="#21134C"
        :scale-interval="0"
      />
    </div>
    <div class="report text-center">
      <h2 class="notranslate">{{ points | points }}</h2>
      <p class="text-uppercase font-size-14 label ">Pontos</p>
      <div class="d-flex justify-content-around mt-4">
        <div>
          <span class="text-uppercase font-size-12">Atual</span>
          <div class="d-block">
            <img v-if="previous.image" :src="require('@/assets/images/graduations/' + previous.image)" class="rounded-circle bg-white" width="80" />
            <img v-else src="@/assets/images/logo-icon.png" class="rounded-circle bg-white" width="80" />
          </div>
          <h3 class="m-0 notranslate">{{ previous.points | points }}</h3>
          <p class="m-0 notranslate">{{ previous.name }} </p>
        </div>
        <div>
          <span class="text-uppercase font-size-12">Próximo</span>
          <div class="d-block">
            <img v-if="next.image" :src="require('@/assets/images/graduations/' + next.image)" class="rounded-circle bg-white" width="80" />
            <img v-else src="@/assets/images/logo-icon.png" class="rounded-circle bg-white" width="80" />
          </div>
          <h3 class="m-0 notranslate">{{ next.points | points }}</h3>
          <p class="m-0 notranslate">{{ next.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.report {
  margin-top: -90px;
}
.label {
  line-height: 13px;
}
</style>